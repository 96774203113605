<script lang="ts" setup>
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import axios from "axios"; // register Swiper custom elements
// register Swiper custom elements
register();

useHead({
    title: "American Book Company",
    meta: [
        {
            name: "description",
            content:
                "American Book Company is your home for an expansive resource providing detailed, powerful, and specific educational and test prep content for state and national curriculum for your needs in the classroom and tutoring along with online testing that is accessible on all devices.",
        },
    ],
    bodyAttrs: {
        class: "@apply m-0 p-0 font-lato text-base text-gray-800",
    },
    script: [
        !String(process.env.APP_URL).toLowerCase().includes("abc.test")
            ? {
                  type: "text/javascript",
                  innerHTML:
                      "( function( a , b , c , d , e , f , g ) { c[d] = c[d] || function() { (c[d].q = c[d].q || []).push(arguments); }; c[ '_lsAlias' ] = c[ d ]; e = a.createElement(b); e.type = 'text/javascript'; e.async = true; e.src = 'https://app.chatsupport.co/api/client/get/script/LS-dec6d4e5'; f = function() { g = a.getElementsByTagName(b)[0]; g.parentNode.insertBefore( e , g ); }; c.addEventListener( 'load' , f ); } )( document , 'script' , window , '_ls' ); _ls( 'init' , { 'projectId' : 'LS-dec6d4e5' } );",
                  tagPosition: "bodyClose",
              }
            : {},
    ],
});

// set our defaults for axios so we always send credentials and XSRF token headers
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
